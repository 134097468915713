import React from 'react';
import Header from '../components/layout/header/Header';
import Error from '../components/errors/404/Error';
import Footer from '../components/layout/footer/Footer';

const Error404Page = () => {
  return (
    <>
      <Header />
      <main>
        <Error />
      </main>
      <div className="hidden lg:block">
        <Footer noVerticalMargin />
      </div>
    </>
  );
};

export default Error404Page;
