import React from 'react';
import { useRouter } from 'next/router';
import styles from '../errorStyles.module.scss';
import { Button } from '../../common';

const Error = () => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <div
        className={`${styles.error} ${styles.notFound} justify-center items-start flex-col`}
      >
        <div className="flex items-end justify-between w-full mb-8 lg:mb-10">
          <h1 className="text-5xl leading-none lg:text-8.5xl">404</h1>
          <img
            src="/images/illustration-dishes.svg"
            alt="Dishes"
            className={`lg:hidden sm:pr-8 ${styles.singleIllustration}`}
          />
        </div>
        <p className="mb-5 text-2xl	leading-normal lg:text-4xl lg:leading-normal">
          Ooops... Something went wrong...
        </p>
        <p className="mb-8 text-sm leading-normal lg:text-base lg:mb-10">
          It seems like the page you are looking for is no longer here or is in
          development. We suggest you go back or go to homepage while we fixing
          the problem.
        </p>
        <div className={styles.buttons}>
          <Button
            as="a"
            className={`mr-4 ${styles.button}`}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              router.back();
            }}
          >
            Go back
          </Button>
          <Button as="a" className={styles.button} filled={false} href="/">
            Go home
          </Button>
        </div>
      </div>
      <img
        src="/images/illustration-dishes.svg"
        alt="dishes"
        className={`hidden w-104 lg:block ${styles.singleIllustration}`}
      />
    </div>
  );
};

export default Error;
